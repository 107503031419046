.widgetEditorFrame {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.2s ease-in-out;
  z-index: -1;
  pointer-events: none;
}

.widgetEditorFrame--visible {
  opacity: 1;
  transform: translateY(0);
  z-index: 11;
  pointer-events: all;
}