.container {
  color: #000;
  z-index: 101;
  min-width: 20rem;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
}
