.container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  width: 16rem;
}

.logo {
  height: 45px;
  margin: 20px;
}
