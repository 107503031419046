/* Liquid */
#shop_liquid_editor .monaco-editor .mtk20,
#shop_liquid_editor .monaco-editor .mtk23 {
  color: #50248f;
}

#shop_liquid_editor .monaco-editor .mtk5 {
  color: #de3618;
}

#shop_liquid_editor .monaco-editor .mtk7 {
  color: #00848e;
}

#shop_liquid_editor .monaco-editor .mtk4 {
  color: #108043;
}

#shop_liquid_editor .monaco-editor .mtk1 {
  color: #50248f;
  font-weight: bold;
}

/* CS */

#shop_template_css_editor .monaco-editor .mtk23 {
  color: #50248f;
}

#shop_template_css_editor .monaco-editor .mtk20 {
  color: #de3618;
}

/* JS */

#shop_js_editor .monaco-editor .mtk6 {
  color: #9c6ade;
}

#shop_js_editor .monaco-editor .mtk20 {
  color: #de3618;
}

#shop_js_editor .monaco-editor .mtk22 {
  color: #de3618;
}

/* JSON  */
#shop_json_editor .monaco-editor .mtk20 {
  color: #50248f;
}

#shop_json_editor .monaco-editor .mtk5 {
  color: #de3618;
}

#shop_json_editor .monaco-editor .mtk7 {
  color: #00848e;
}
