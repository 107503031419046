.pickerWrap {
  position: absolute;
  right: 0;
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.15s ease-in-out;
}
.pickerWrapTop {
  transform: translateY(-105%);
  top: 0;
}
.pickerWrapBottom {
  transform: translateY(105%);
  bottom: 0;
}

.pickerWrapVisible {
  opacity: 1;
  pointer-events: all;
}
.cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}
