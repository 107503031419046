.container {
  position: fixed;
  top: 0;
  left: 16rem;
  height: 65px;
  right: 0;
  overflow: hidden;
  background-color: #fff;
  z-index: 1;
}
