.storeFrame {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.2s ease-in-out;
  z-index: -1;
  background-color: #fff;
  pointer-events: none;
}

.storeFrame--visible {
  opacity: 1;
  transform: translateY(0);
  z-index: 11;
  pointer-events: all;
}