.inline {
  display: inline-block;
  vertical-align: top;
}

.popper {
  border-radius: 2px;
  max-width: 320px;
  opacity: 1;
  padding: 5px;
  z-index: 100;
  width: auto;
  background-color: rgba(16, 16, 16, 0.95);;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 12px;
}

.arrow {
  border-style: solid;
  height: 0;
  pointer-events: none;
  position: absolute;
  width: 0;
}

.popper[data-placement^='top'] {
  margin-bottom: 8px;

  .arrow {
    border-color: #000 transparent transparent transparent;
    border-width: 5px 5px 0 5px;
    margin-top: 5px;
  }
}

.popper[data-placement^='bottom'] {
  margin-top: 8px;

  .arrow {
    border-color: transparent transparent #000 transparent;
    border-width: 0 5px 5px 5px;
    margin-bottom: 5px;
    top: -5px;
  }
}

.popper[data-placement^='right'] {
  margin-left: 8px;

  .arrow {
    border-color: transparent #000 transparent transparent;
    border-width: 5px 5px 5px 0;
    left: -5px;
  }
}

.popper[data-placement^='left'] {
  margin-right: 8px;

  .arrow {
    border-color: transparent transparent transparent #000;
    border-width: 5px 0 5px 5px;
    right: -5px;
  }
}

.popper[data-x-out-of-boundaries] {
  display: none;
}
