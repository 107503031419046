.rw-input,
.rw-dropdown-list-autofill,
.rw-filter-input {
  color: #000;
}

.rw-widget-container.rw-state-focus,
.rw-state-focus > .rw-widget-container,
.rw-widget-container.rw-state-focus:hover,
.rw-state-focus > .rw-widget-container:hover {
  border-color: rgb(79, 70, 229);
  box-shadow: 0 0 8px rgba(79, 70, 229, 0.6);
}

.rw-list-option {
  color: #000;
}
.rw-list-option.rw-state-focus,
.rw-list-option.rw-state-focus:hover,
.rw-cell.rw-state-focus,
.rw-cell.rw-state-focus:hover {
  border-color: rgb(79, 70, 229);
  color: #000;
}

.rw-cell.rw-state-selected,
.rw-cell.rw-state-selected:hover,
.rw-list-option.rw-state-selected,
.rw-list-option.rw-state-selected:hover {
  background-color: rgb(79, 70, 229);
  color: #fff;
}
