.react-calendar__tile--active {
  background: #006edc;
  color: white;
}

.react-daterange-picker__wrapper {
  align-items: center;
}

.react-daterange-picker__range-divider {
  margin-right: 4px;
}
