.container {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  background: #153a4a;
  z-index: 9;
}

.viewWebsiteButton {
  background: #ff9f8a;
}
