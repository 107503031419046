.loaderDots1,
.loaderDots2,
.loaderDots3 {
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.1s infinite ease-in-out both;
  animation: sk-bouncedelay 1.1s infinite ease-in-out both;
}

.loaderDots1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loaderDots2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.loaderSpinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  margin: 2px;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #f47a60 transparent transparent transparent;
}

.loaderSpinner div:nth-child(1) {
  animation-delay: -0.45s;
}

.loaderSpinner div:nth-child(2) {
  animation-delay: -0.3s;
}

.loaderSpinner div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
