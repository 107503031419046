.disableScroll {
  overflow: hidden !important;
  width: 100%;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 0;
  left: 0;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 11;
}

@media only screen and (max-width: 576px) {
  .overlay {
    padding: 0;
    display: flex;
  }
}
