.star {
  animation: starFade ease-in-out infinite alternate;
  animation-duration: 2500ms;
}
.star:nth-child(1) {
  animation-delay: 0ms;
}
.star:nth-child(2) {
  animation-delay: 400ms;
}
.star:nth-child(3) {
  animation-delay: 600ms;
}
.star:nth-child(4) {
  animation-delay: 800ms;
}
.star:nth-child(5) {
  animation-delay: 1000ms;
}

@keyframes starFade {
  0% {
    opacity: 0;
    transform: translate3d(0, -10%, 0);
  }
  20% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  80% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, -10%, 0);
  }
}
